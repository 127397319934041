import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Container>
        <Row>
          <Col className='text-center py-3'>
            <p><h3>Accomodation Section &copy; {currentYear}</h3></p>
            <p>All participants staying at this hotel will be provided with a shuttle to the Congress venue. Reservations can be made via the following link by stating your name and country of origin. For confirmation, please to whatsapp +62 815-5996-6014 OR +62 813-8084-0910, Thank you</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
