import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useGetProductDetailsQuery,
  useCreateReviewMutation,
} from '../slices/productsApiSlice';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import { addToCart } from '../slices/cartSlice';

const ProductScreen = () => {
  const globalCurrency = useSelector((state)=>state.currency)
  const formatUang = (number) => {
      // Convert USD to IDR if currency is IDR
      const convertedPrice = globalCurrency === 'IDR' ? number * 15500 : number;
      var counter = 1;
      var uang = String(convertedPrice);
      var hasil = '';
      for (var i = uang.length - 1; i >= 0; i--) {
          if (counter % 3 === 0 && i !== 0 && globalCurrency === "IDR") {
              hasil = '.' + uang[i] + hasil;
          } else {
              hasil = uang[i] + hasil;
          }
          counter++;
      }
      const curr = globalCurrency === "IDR" ? "Rp " : "$ "
      return `${curr} ${hasil}`;
  };
  const { id: productId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [qty, setQty] = useState(0);
  const [options, setOptions] = useState([
    { id: 1, value: '21 July 2024', clicked: false },
    { id: 2, value: '22 July 2024', clicked: false },
    { id: 3, value: '23 July 2024', clicked: false }
  ]);

  const handleOptionClick = (id) => {
    const updatedOptions = options.map(option => {
      if (option.id === id) {
        if (!option.clicked) {
          setQty(qty + 1);
        } else {
          setQty(qty - 1);
        }
        return { ...option, clicked: !option.clicked };
      }
      return option;
    });
    setOptions(updatedOptions);
  };
  
  const addToCartHandler = () => {
    const selectedOptions = options.filter(option => option.clicked).map(option => option.value);
        dispatch(addToCart({ ...product, qty, selectedOptions }));
        navigate('/cart');
  };

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const { userInfo } = useSelector((state) => state.auth);

  const [createReview, { isLoading: loadingProductReview }] =
    useCreateReviewMutation();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        productId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success('Review created successfully');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Link className='btn btn-light my-3' to='/'>
        Go Back
      </Link>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta title={product.name} description={product.description} />
          <Row>
            <Col md={6}>
              <Image src={product.image} alt={product.name} fluid />
            </Col>
            <Col md={3}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>
                <ListGroup.Item>Price: {formatUang(product.price)}</ListGroup.Item>
                <ListGroup.Item>
                  Description: {product.description}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong>{formatUang(product.price)}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  

                  {/* Qty Select */}
                
                    <ListGroup.Item>
                      <Row>
                        <Col>Night</Col>
                        <Col>
                        <Form>
                                {options.map(option => (
                                <div key={option.id}>
                                <input
                                type="checkbox"
                                id={option.value}
                                checked={option.clicked}
                                onChange={() => handleOptionClick(option.id)}
                                />
                                <label htmlFor={option.value}>{option.value}</label>
                                </div>
                                ))}
                            </Form>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                

                  <ListGroup.Item>
                    <Button
                      className='btn-block'
                      type='button'
                      onClick={addToCartHandler}
                    >
                      Add To Cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className='review'>
            <Col md={6}>
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant='flush'>
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Write a Customer Review</h2>

                  {loadingProductReview && <Loader />}

                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group className='my-2' controlId='rating'>
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as='select'
                          required
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=''>Select...</option>
                          <option value='1'>1 - Poor</option>
                          <option value='2'>2 - Fair</option>
                          <option value='3'>3 - Good</option>
                          <option value='4'>4 - Very Good</option>
                          <option value='5'>5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className='my-2' controlId='comment'>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as='textarea'
                          row='3'
                          required
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingProductReview}
                        type='submit'
                        variant='primary'
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to='/login'>sign in</Link> to write a review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
